<template>
    <section class="banner overlay backgroundImg z-1">
        <h1 class="z1" style="white-space: nowrap;">Magie sous les pins</h1>
        <p class="z1">Une école de magie valbonnaise ouverte à tout public, enfants comme adultes. Des cartes aux pièces, en passant par le mentalisme et l'hypnose.</p>
    </section>
    <section class="contentCenter">
        <h2>Les valeurs de notre école</h2>
        <div class="containerCenterMultiple v-space">
            <MainButton value="Pédagogie" color="$secondary" />
            <MainButton value="Technique" color="$blue" />
            <MainButton value="Suivi" color="$orange" />
        </div>
        <!-- <p>Aurum missus seditionum coeptantem sunt praepositus seditionum insidiarum praepositus est et tuto et consenuit tumor ne Eusebius Cabillona occultius.</p> -->
        <p>Nous regroupons nos élèves par petits groupes d'âges et de niveaux pour favoriser leur apprentissage. Nous prenons également le soin de les accompagner personnellement pour que chacun puisse avancer à son rythme.</p>
        <img alt="Mains mélangeant un jeu de cartes" src="../assets/images/shuffle.png" style="width: 60%">
        <p><q class="lisible">Un spectateur qui reste sans voix, c'est un tour réussi.</q></p>
        <p>C'est pour cette raison que notre enseignement aborde diverses notions liées au thème de la magie, toutes aussi indispensables les unes que les autres. La magie est un art, elle se travaille, se personnalise, elle vit en chacun de nous.</p>
    </section>
    <section class="clearPaddingBottom">
        <ImageTextLayout
            imageUrl="flo_1"
            imageAlt="Flo Rayan faisant un tour de magie"
            text="« Des surprises pour les petits enfants, de la technique pour les grands enfants, dans les deux cas mon histoire vous bercera et sa chutte vous bluffera. »"
        />
    </section>

</template>

<style lang="scss">
.banner {
    height: 100vh;
    background-image: url('../assets/images/banner.png');
    &:after {
        background:rgba(0,0,0,0.2);
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 601px) {
        padding-left: 40px;
        padding-right: 40%;
    }
    @media screen and (max-width: 600px) {
        padding-left: 20px;
        padding-right: 20%;
    }
}

@media screen and (min-width: 601px) {
    .banner {
        padding-top: 80px;
    }
}
@media screen and (max-width: 600px) {
    .banner {
        height: 60vh;
    }
}
</style>

<script>
import MainButton from '../components/MainButton.vue';
import ImageTextLayout from '../components/ImageTextLayout.vue';

export default {
    components: { MainButton, ImageTextLayout },
    data() {
        return {
            showMobileMenu: false,
        };
    },
}
</script>